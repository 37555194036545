<template>
    <div id="NewContainer">
        <div id="header">
            <div class="header_icons">
                {{ gameState.puntos.value }} puntos
            </div>
            <div class="header_icons">
                <img src="../assets/icono_speaker.svg" alt="Sonido" class="icono">
                <router-link to="/"><img @click="soundButton.play()" src="../assets/icono_home.svg" alt="Inicio" class="icono"></router-link>
            </div>
        </div>

        <div id="swipable">
            <div
              id="new_foto"
              :style="{'background-image': 'url(' + require('../assets/website/pruebate/'+gameState.current_new_id.value+'.jpg') + ')'}">
              <div id="flechas">
                <img src="../assets/icono_swpder.svg" class="flecha">
                <img src="../assets/icono_swpizq.svg" class="flecha">
              </div>
            </div>
            <div id="new_content">
                <div id="new_content_title">
                    {{ gameState.current_new.value.titulo }}
                </div>
                <div id="new_content_subtitle">
                    {{ gameState.current_new.value.subtitulo }}
                </div>
                <div id="new_content_body" v-html="gameState.current_new.value.body">
                </div>
            </div>
        </div>

        <div id="bottom">
            Desliza a la <span class="blue_text">derecha si confÍas</span> en esta información o <span class="blue_text">izquierda si no confÍas</span>
        </div>
    </div>
</template>

<script>
import Hammer from 'hammerjs'
import useJuego from '../store/contenidoJuego'

export default {
  name: 'NewContainer',
  setup () {
    const gameState = useJuego()
    gameState.popNew()

    return {
      gameState: gameState
    }
  },
  data () {
    return {
      isPanning: false,
      startPosX: 0,
      startPosY: 0,
      isDraggingFrom: 1,
      soundButton: document.querySelector('#soundButton')
    }
  },
  mounted () {
    const container = document.querySelector('#NewContainer')
    const swipable = document.querySelector('#swipable')
    const soundSwipe = document.querySelector('#soundSwipe')
    var hammertime = new Hammer(swipable)
    hammertime.add(
      new Hammer.Pan({
        position: Hammer.position_ALL,
        threshold: 0
      })
    )

    hammertime.on('pan', (e) => {
      if (e.srcEvent.type !== 'pointercancel') {
        if (!this.isPanning) {
          this.isPanning = true

          // remove transition property
          swipable.style.transition = null

          // get card coordinates in pixels
          const style = window.getComputedStyle(swipable)
          const mx = style.transform.match(/^matrix\((.+)\)$/)
          this.startPosX = mx ? parseFloat(mx[1].split(', ')[4]) : 0
          this.startPosY = mx ? parseFloat(mx[1].split(', ')[5]) : 0

          // get finger position on card, top (1) or bottom (-1)
          const bounds = swipable.getBoundingClientRect()
          this.isDraggingFrom =
            (e.center.y - bounds.top) > swipable.clientHeight / 2 ? -1 : 1
        }

        let posX = e.deltaX + this.startPosX
        const posY = e.deltaY + this.startPosY

        const propX = e.deltaX / container.clientWidth
        const dirX = e.deltaX < 0 ? -1 : 1
        const deg = this.isDraggingFrom * dirX * Math.abs(propX) * 45

        swipable.style.transform = 'translateX(' + posX + 'px) translateY(' + posY + 'px) rotate(' + deg + 'deg)'

        if (e.isFinal) {
          this.isPanning = false
          swipable.style.transition = 'transform 200ms ease-out'

          if (Math.abs(propX) > 0.3) {
            soundSwipe.play()
            if (e.direction === Hammer.DIRECTION_RIGHT) {
              posX = container.clientWidth
            } else if (e.direction === Hammer.DIRECTION_LEFT) {
              posX = -(container.clientWidth + swipable.clientWidth)
            }
            swipable.style.transform = 'translateX(' + posX + 'px) translateY(' + posY + 'px) rotate(' + deg + 'deg)'
            setTimeout(() => {
              this.gameState.current_guess.value = dirX
              this.$router.push('/juego/resultado')
              swipable.style.transform = 'translateX(-50%) translateY(-50%) rotate(0deg)'
            }, 200)
          } else {
            swipable.style.transform = 'translateX(-50%) translateY(-50%) rotate(0deg)'
          }
        }
      }
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#NewContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

#header {
    width: 100%;

    display: flex;
    justify-content: space-between;
    padding: 10px;
}
.header_icons {
    display: flex;
    align-items: center;
    color: white;
    font: 25px "Bebas Neue";
}
.icono {
    width: 25px;
    height: auto;
    margin: 0px 4px;
}
.icono:hover {
    cursor: pointer;
    transform: scale(1.1);
}

#swipable {
    width: 80%;
    max-height: 80vh;
    display: flex;
    flex-direction: column;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
#new_foto {
    border: 3px solid white;
    border-radius: 15px;

    overflow: hidden;
    height: 0;
    padding-top: 56.25%;

    background-repeat: no-repeat;
    background-size: cover;

    position: relative;
}
#flechas {
  position: absolute;
  top: 35%;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.flecha {
  width: 15px;
}
#new_content {
    margin: -5px 5px 10px 5px;
    border-radius: 0px 0px 15px 15px;
    background-color: white;

    padding: 10px 15px;

    overflow: scroll;
    flex-grow: 1;
}
#new_content_title {
    color: #54ceea;
    font: 32px "Bebas Neue";
    text-align: left;
    line-height: 0.8;

    padding: 3px 0px;

    border-width: 0px 0px 1px 0px;
    border-style: solid;
    border-color: #0C1D3E;
}
#new_content_subtitle {
    color: #54ceea;
    font: 11px "Bebas Neue";
    text-align: left;

    padding: 3px 0px;
}
#new_content_body {
    text-align: justify;
    font: 12px "Helvetica";
    margin-bottom: 5px;
}

#bottom {
    width: 70%;
    border-width: 2px 2px 0px;
    border-radius: 15px 15px 0px 0px;
    border-style: solid;

    font-family: "Bebas Neue";
    font-size: 12px;
    color: white;

    margin: auto auto 0px;
    padding: 10px 30px;
}
.blue_text {
    color: #54ceea;
}

@media screen and (min-width: 800px) {
  #swipable {
      width: 90%;
      max-height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
  }
  #new_foto {
      flex-shrink: 0;
      border: 3px solid white;
      border-radius: 15px;

      overflow: visible;
      width: 400px;
      height: 225px;
      padding-top: 0;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      margin-top: 80px;
      margin-right: -50px;
      z-index: 1;
  }
  #flechas {
    display: none;
  }
  #new_content {
      flex-grow: 1;
      border-radius: 25px;
      background-color: white;

      padding: 20px 25px 15px 60px;
      max-height: 70vh;

      overflow: auto;
      flex-grow: 0;
  }
  #new_content_title {
      color: #54ceea;
      font: 60px "Bebas Neue";
      text-align: left;
      line-height: 0.8;

      padding: 3px 0px;

      border-width: 0px 0px 1px 0px;
      border-style: solid;
      border-color: #0C1D3E;
  }
  #new_content_subtitle {
      color: #54ceea;
      font: 18px "Bebas Neue";
      text-align: left;

      padding: 3px 0px;
  }
  #new_content_body {
      text-align: justify;
      font: 20px "Helvetica";
      margin-bottom: 5px;
  }

  #bottom {
      width: 60%;
      border-width: 2px 2px 0px;
      border-radius: 15px 15px 0px 0px;
      border-style: solid;

      font-family: "Bebas Neue";
      font-size: 20px;
      color: white;

      margin: auto auto 0px;
      padding: 10px 30px;
  }
}
</style>
